import * as types from '../../mutationTypes';

export default {
  /* eslint-disable no-param-reassign */

  [types.SET_PAGE_VIEW_TYPE](state, value) {
    state.viewType = value;
  }

  /* eslint-enable no-param-reassign */
};
