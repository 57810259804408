import * as types from '../../mutationTypes';

export default {
  /* eslint-disable no-param-reassign */

  [types.SET_TOGGLE](state, value) {
    state.enabled = !value;
  }

  /* eslint-enable no-param-reassign */
};
