const moment = require('moment-timezone');

const dateTime = (rawDate) => {
  const date = moment(rawDate);

  date.copy = () => {
    return dateTime(date);
  };

  date.addDays = (days) => {
    return date.copy().add(days, 'days');
  };

  date.stringify = (timezone, format) => {
    return date.tz(timezone).format(format);
  };

  date.stringifyAsUtc = (format) => {
    return date.stringify('Etc/Utc', format);
  };

  date.stringifyRawAsUtc = () => {
    return date.stringify('Etc/Utc', 'YYYY-MM-DDTHH:mm:ssZ');
  };

  return date;
};

dateTime.parse = (value, timezone, format) => {
  if (!value || !timezone || !format) {
    throw new Error(
      `failed to parse value ${value} with timezone ${timezone} and format ${format}`
    );
  }

  const momentDateTime = moment.tz(value, format, timezone);
  if (!momentDateTime.isValid()) {
    throw new Error(
      `failed to create DateTime with ${value}, ${timezone} and ${format} as moment marked it as invalid.`
    );
  }

  return dateTime(moment.tz(value, format, timezone));
};

dateTime.fromUnixUtc = (unixUtc) => {
  return dateTime.parse(`${unixUtc}`, 'Etc/Utc', 'X');
};

dateTime.durationAsSeconds = (start, end) => {
  return moment.duration(end.diff(start)).asSeconds();
};

exports.DateTime = dateTime;
