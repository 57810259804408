<template>
  <cs-view wide-nav>
    <template #left-content>
      <slot-indicator slot-name="Page Left Content" width="100%" height="100%">
        <template #content>
          <v-container>
            <v-col cols="10">
              <v-card>
                <div class="justify-center pt-2">Home Page</div>
                <v-row class="ml-3 mb-2">
                  <a :href="`${geminiUrl}`">Gemini</a>
                </v-row>
                <v-row class="ml-3 mb-2">
                  <a :href="`${apolloUrl}`">Apollo</a>
                </v-row>
                <v-row class="ml-3 mb-2">
                  <a :href="`${artemisUrl}`">Artemis</a>
                </v-row>
              </v-card>

              <v-card>
                <div class="justify-center pt-2">Login Page</div>
                <v-row class="ml-3 mb-2">
                  <a :href="`${geminiUrl}/login`">Gemini</a>
                </v-row>
                <v-row class="ml-3 mb-2">
                  <a :href="`${apolloUrl}/login`">Apollo</a>
                </v-row>
                <v-row class="ml-3 mb-2">
                  <a :href="`${artemisUrl}/login`">Artemis</a>
                </v-row>
              </v-card>

              <v-card>
                <div class="justify-center pt-2">Wide Navigation Page</div>
                <v-row class="ml-3 mb-2">
                  <a :href="`${geminiUrl}/wide-nav`">Gemini</a>
                </v-row>
                <v-row class="ml-3 mb-2">
                  <a :href="`${apolloUrl}/wide-nav`">Apollo</a>
                </v-row>
                <v-row class="ml-3 mb-2">
                  <a :href="`${artemisUrl}/wide-nav`">Artemis</a>
                </v-row>
              </v-card>
            </v-col>
          </v-container>
        </template>
      </slot-indicator>
    </template>

    <template #right-content>
      <slot-indicator slot-name="Page Right Content" width="100%" height="100%">
        <template #content>
          <v-container>
            <v-row>
              <v-col>
                <v-row class="ma-3">
                  <v-btn width="100%" @click="onSetLoading"> Set Loading </v-btn>
                </v-row>
                <v-row class="ma-3">
                  <v-btn width="100%" @click="onClearLoading"> Clear Loading </v-btn>
                </v-row>
              </v-col>
              <v-col>
                <v-row class="ma-3">
                  <v-btn width="100%" @click="onShowErrorAlert"> Show Error Alert </v-btn>
                </v-row>
                <v-row class="ma-3">
                  <v-btn width="100%" @click="onShowSuccessAlert"> Show Success Alert </v-btn>
                </v-row>
                <v-row class="ma-3">
                  <v-btn width="100%" @click="onShowInfoAlert"> Show Info Alert </v-btn>
                </v-row>
                <v-row class="ma-3">
                  <v-btn width="100%" @click="onShowWarningAlert"> Show Warning Alert </v-btn>
                </v-row>
                <v-row class="ma-3">
                  <v-btn width="100%" @click="onClearAlert"> Clear Alert </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </slot-indicator>
    </template>
  </cs-view>
</template>

<script>
import { CSBase } from '@complispace/cs-design-system';
import { mapMutations } from 'vuex';
import SlotIndicator from '../components/SlotIndicator';
import * as types from '../store/mutationTypes';

export default {
  name: 'WideNav',

  components: { SlotIndicator },

  extends: CSBase,

  data() {
    return {
      geminiUrl: process.env.VUE_APP_GEMINI_URL,
      apolloUrl: process.env.VUE_APP_APOLLO_URL,
      artemisUrl: process.env.VUE_APP_ARTEMIS_URL,
      tokenText: '',
      decodedTokenText: '',
      userText: '',
      tokenLoading: false,
      userLoading: false,
      logoutLoading: false
    };
  },

  async mounted() {
    this.setPageViewType('wide-nav');
  },

  methods: {
    ...mapMutations({
      setPageViewType: types.VIEW_TYPE_SET_PAGE_VIEW_TYPE
    }),

    onSetLoading() {
      this.setLoading(true);
    },

    onClearLoading() {
      this.clearLoading();
    },

    onShowErrorAlert() {
      this.showErrorAlert('This is an error alert.');
    },

    onShowSuccessAlert() {
      this.showSuccessAlert('This is an success alert.');
    },

    onShowInfoAlert() {
      this.showInfoAlert('This is an info alert.');
    },

    onShowWarningAlert() {
      this.showWarningAlert('This is an warning alert.');
    },

    onShowErrorDialogAlert() {
      this.showErrorAlert(
        'This configuration will prevent users from logging in via Ideagen Home’s authentication solution. Make sure all fields below are filled in correctly in order to prevent any login issues.',
        true
      );
    },

    onShowSuccessDialogAlert() {
      this.showSuccessAlert('This is an success alert.', true);
    },

    onShowInfoDialogAlert() {
      this.showInfoAlert('This is an info alert.', true);
    },

    onShowWarningDialogAlert() {
      this.showWarningAlert('This is an warning alert.', true);
    },

    onClearAlert() {
      this.clearAlert();
    }
  }
};
</script>

<style scoped></style>
