import * as types from '../../mutationTypes';

export default {
  /* eslint-disable no-param-reassign */
  [types.SET_CONFIG](state, { endpoint, region }) {
    state.config = {
      endpoint,
      region
    };
  }
  /* eslint-enable no-param-reassign */
};
