import Amplify from '@aws-amplify/core';
import { graphqlOperation, API } from '@aws-amplify/api';

const query = (token) => (queryStr, variables) => {
  const partial = graphqlOperation(queryStr, variables, token);
  const ops = {
    query: partial.query,
    variables: partial.variables,
    authMode: 'AWS_LAMBDA',
    authToken: partial.authToken
  };

  return API.graphql(ops);
};

export const appsyncClient = (config, token) => {
  Amplify.configure({
    aws_appsync_graphqlEndpoint: config.endpoint,
    aws_appsync_region: config.region,
    aws_appsync_authenticationType: 'AWS_LAMBDA'
  });

  return {
    query: query(token),
    subscribe: (queryStr, variables, next) => {
      return query(token)(queryStr, variables).subscribe({
        next: ({ provider, value }) => {
          next(value, provider);
        }
      });
    }
  };
};

export default appsyncClient;
