<template>
  <cs-view single-view>
    <template #content>
      <slot-indicator slot-name="Single View Page Content" width="100%" height="100%">
        <template #content>
          <v-container>
            <v-row>
              <v-col>
                <v-card>
                  <div class="justify-center pt-2">Home Page</div>
                  <v-row class="ml-3 mb-2">
                    <a :href="`${geminiUrl}`">Gemini</a>
                  </v-row>
                  <v-row class="ml-3 mb-2">
                    <a :href="`${apolloUrl}`">Apollo</a>
                  </v-row>
                  <v-row class="ml-3 mb-2">
                    <a :href="`${artemisUrl}`">Artemis</a>
                  </v-row>
                </v-card>

                <v-card>
                  <div class="justify-center pt-2">Login Page</div>
                  <v-row class="ml-3 mb-2">
                    <a :href="`${geminiUrl}/login`">Gemini</a>
                  </v-row>
                  <v-row class="ml-3 mb-2">
                    <a :href="`${apolloUrl}/login`">Apollo</a>
                  </v-row>
                  <v-row class="ml-3 mb-2">
                    <a :href="`${artemisUrl}/login`">Artemis</a>
                  </v-row>
                </v-card>

                <v-card>
                  <div class="justify-center pt-2">Wide Navigation Page</div>
                  <v-row class="ml-3 mb-2">
                    <a :href="`${geminiUrl}/wide-nav`">Gemini</a>
                  </v-row>
                  <v-row class="ml-3 mb-2">
                    <a :href="`${apolloUrl}/wide-nav`">Apollo</a>
                  </v-row>
                  <v-row class="ml-3 mb-2">
                    <a :href="`${artemisUrl}/wide-nav`">Artemis</a>
                  </v-row>
                </v-card>
              </v-col>
              <v-col>
                <v-row class="ma-3">
                  <cs-button width="100%" label="Set Loading" @click="onSetLoading" />
                </v-row>
                <v-row class="ma-3">
                  <cs-button width="100%" label="Clear Loading" @click="onClearLoading" />
                </v-row>
              </v-col>
              <v-col>
                <v-row class="ma-3">
                  <form-dialogs-test> </form-dialogs-test>
                </v-row>
              </v-col>
              <v-col>
                <v-row class="ma-3">
                  <cs-button width="100%" label="Show Error Alert" @click="onShowErrorAlert" />
                </v-row>
                <v-row class="ma-3">
                  <cs-button width="100%" label="Show Success Alert" @click="onShowSuccessAlert" />
                </v-row>
                <v-row class="ma-3">
                  <cs-button width="100%" label="Show Info Alert" @click="onShowInfoAlert" />
                </v-row>
                <v-row class="ma-3">
                  <cs-button width="100%" label="Show Warning Alert" @click="onShowWarningAlert" />
                </v-row>
                <v-row class="ma-3">
                  <cs-button
                    width="100%"
                    label="Show Clickable Error Alert (1 href & 1 func)"
                    @click="onShowErrorAlertWithHrefAndFunc"
                  />
                </v-row>
                <v-row class="ma-3">
                  <cs-button
                    width="100%"
                    label="Show Clickable Success Alert (1 href)"
                    @click="onShowSuccessAlertWithHref"
                  />
                </v-row>
                <v-row class="ma-3">
                  <cs-button
                    width="100%"
                    label="Show Clickable Info Alert (1 func)"
                    @click="onShowInfoAlertWithFunc"
                  />
                </v-row>
                <v-row class="ma-3">
                  <cs-button
                    width="100%"
                    label="Show Clickable Warning Alert (1 href & 1 func)"
                    @click="onShowWarningAlertWithHrefAndFunc"
                  />
                </v-row>
                <v-row class="ma-3">
                  <cs-button width="100%" label="Clear Alert" @click="onClearAlert" />
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="engineeringMode">
                <v-row class="ma-3">
                  <cs-button
                    width="100%"
                    :loading="tokenLoading"
                    label="Get Token"
                    @click="onGetToken"
                  />
                </v-row>
                <v-row class="ma-3">
                  <cs-button
                    width="100%"
                    :loading="userLoading"
                    label="Get User"
                    @click="onGetUser"
                  />
                </v-row>
              </v-col>
              <v-col v-if="engineeringMode">
                <v-row class="ma-3">
                  <cs-button
                    width="100%"
                    :loading="logoutLoading"
                    label="Logout Federated"
                    @click="onLogout"
                  />
                </v-row>
              </v-col>
              <v-col v-if="engineeringMode">
                <v-row class="ma-3">
                  <v-card v-if="!!tokenText">
                    <v-card-title> Token: </v-card-title>
                    <v-card-text class="token">
                      <div class="icon-btn" @click.stop.prevent="copyText">
                        <v-icon size="small"> mdi-content-copy </v-icon>
                      </div>
                      <pre class="text-wrap">
                      {{ tokenText }}
                      </pre>
                      <input id="token-text" type="hidden" :value="tokenText" />
                    </v-card-text>
                  </v-card>
                </v-row>
                <v-row class="ma-3">
                  <v-card v-if="!!decodedTokenText">
                    <v-card-title>Decoded Token:</v-card-title>
                    <v-card-text class="code">
                      <pre>{{ decodedTokenText }}</pre>
                    </v-card-text>
                  </v-card>
                </v-row>
                <v-row class="ma-3">
                  <v-card v-if="!!userText">
                    <v-card-title>User:</v-card-title>
                    <v-card-text class="code">
                      <pre>{{ userText }}</pre>
                    </v-card-text>
                  </v-card>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </slot-indicator>
    </template>
  </cs-view>
</template>

<script>
import jwt from 'jsonwebtoken';
import { CSBase } from '@complispace/cs-design-system';
import { mapGetters, mapMutations } from 'vuex';
import SlotIndicator from '../components/SlotIndicator';
import * as types from '../store/mutationTypes';
import FormDialogsTest from '../components/FormDialogsTest';

export default {
  name: 'HomePage',

  components: {
    SlotIndicator,
    'form-dialogs-test': FormDialogsTest
  },

  extends: CSBase,

  data() {
    return {
      geminiUrl: process.env.VUE_APP_GEMINI_URL,
      apolloUrl: process.env.VUE_APP_APOLLO_URL,
      artemisUrl: process.env.VUE_APP_ARTEMIS_URL,
      tokenText: '',
      decodedTokenText: '',
      userText: '',
      tokenLoading: false,
      userLoading: false,
      logoutLoading: false
    };
  },

  computed: {
    ...mapGetters({
      user: 'cs-session/user',
      engineeringMode: 'engineering/enabled'
    })
  },

  async mounted() {
    this.setPageViewType('single-view');
  },

  methods: {
    ...mapMutations({
      setPageViewType: types.VIEW_TYPE_SET_PAGE_VIEW_TYPE
    }),

    onSetLoading() {
      this.setLoading(true);
    },

    onClearLoading() {
      this.clearLoading();
    },

    onShowErrorAlert() {
      this.showErrorAlert(
        'This is an error alert.This is an error alert.This is an error alert.This is an error alert.This is an error alert.This is an error alert.This is an error alert.This is an error alert.This is an error alert.This is an error alert.This is an error alert.This is an error alert. This is an error alert.This is an error alert.This is an error alert.This is an error alert.This is an error alert.This is an error alert.This is an error alert.This is an error alert.This is an error alert.This is an error alert.This is an error alert.This is an error alert. This is an error alert.This is an error alert.This is an error alert.This is an error alert.This is an error alert.This is an error alert.This is an error alert.This is an error alert.This is an error alert.This is an error alert.This is an error alert.This is an error alert.'
      );
    },

    onShowErrorAlertWithHrefAndFunc() {
      const options = {
        href: 'https://complispace.zendesk.com/hc/en-us/requests/new',
        func: () => {
          // eslint-disable-next-line no-alert
          alert('clickable alert function executed');
        }
      };
      this.showErrorAlert(
        'This is a clickable error alert. Click <href>here</href> to be redirected to any defined URL. Click <func>here</func> to execute any function.',
        false,
        options
      );
    },

    onShowSuccessAlert() {
      this.showSuccessAlert('This is an success alert.');
    },

    onShowSuccessAlertWithHref() {
      const options = {
        href: 'https://complispace.zendesk.com/hc/en-us/requests/new'
      };
      this.showSuccessAlert(
        'This is a clickable success alert. Click <href>here</href> to be redirected to any defined URL.',
        false,
        options
      );
    },

    onShowInfoAlert() {
      this.showInfoAlert('This is an info alert.');
    },

    onShowInfoAlertWithFunc() {
      const options = {
        func: () => {
          // eslint-disable-next-line no-alert
          alert('clickable alert function executed');
        }
      };
      this.showInfoAlert(
        'This is a clickable success alert. Click <func>here</func> to execute any function.',
        false,
        options
      );
    },

    onShowWarningAlert() {
      this.showWarningAlert('This is an warning alert.');
    },

    onShowWarningAlertWithHrefAndFunc() {
      const options = {
        href: 'https://complispace.zendesk.com/hc/en-us/requests/new',
        func: () => {
          // eslint-disable-next-line no-alert
          alert('clickable alert function executed');
        }
      };
      this.showWarningAlert(
        'This is a clickable warning alert. Click <href>here</href> to be redirected to any defined URL. Click <func>here</func> to execute any function.',
        false,
        options
      );
    },

    onClearAlert() {
      this.clearAlert();
    },

    async onGetToken() {
      this.tokenLoading = true;
      this.tokenText = '';
      this.decodedTokenText = '';

      this.tokenText = await this.$store.dispatch('cs-session/getToken');
      const decoded = jwt.decode(this.tokenText, { complete: true });
      this.decodedTokenText = JSON.stringify(decoded, undefined, 2);
      this.tokenLoading = false;
    },

    async onGetUser() {
      this.userLoading = true;
      this.userText = '';
      const auth = await this.$store.dispatch('auth0/buildClient');
      const user = await auth.getUser();
      this.userText = JSON.stringify(user, undefined, 2);
      this.userLoading = false;
    },

    async onLogout() {
      this.logoutLoading = true;
      await this.$store.dispatch('cs-session/logout', { federated: true });
      this.logoutLoading = false;
    },

    copyText() {
      const tokenText = document.querySelector('#token-text');
      tokenText.setAttribute('type', 'text');
      tokenText.select();
      try {
        document.execCommand('copy');
        tokenText.style.display = 'none';
        this.showSuccessAlert('Token Copied !!');
      } catch (error) {
        this.showErrorAlert(error.message);
      }
    }
  }
};
</script>

<style scoped>
.code {
  text-align: left;
}

.token {
  text-align: left;
}

.icon-btn {
  margin-bottom: 10px;
  cursor: pointer;
}

.dialogTitle {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
</style>
