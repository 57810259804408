<template>
  <v-card elevation="3" :style="{ height, width }">
    <div class="slot-indicator" :style="{ height, width }">
      <div class="slot-title">{{ slotName }}</div>
      <div class="slot-container">
        <slot name="content"></slot>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'SlotIndicator',
  props: {
    slotName: { type: String, require: true, default: '' },
    height: { type: String, require: true, default: '' },
    width: { type: String, require: true, default: '' }
  }
};
</script>

<style scoped>
.slot-indicator {
  border-color: red;
  border-style: dashed;
  border-radius: 0.3rem;
  border-width: 0.12rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: blue;
  text-align: center;
}

.slot-container {
  width: 100%;
  height: 100%;
}

.slot-title {
  margin: 0.5rem 0 0.5rem 0;
}
</style>
