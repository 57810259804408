<template>
  <cs-view single-view>
    <template #content>
      <div class="justify-center pt-2">Testing Internalization</div>
      <v-card class="ma-0 pa-1">
        <v-container>
          <v-row class="mb-2">
            <v-col align-self="center">
              <v-btn-toggle
                v-model="localeIndex"
                color="primary"
                variant="outlined"
                divided
                density="compact"
                @update:model-value="onLanguageChange"
              >
                <v-btn>EN</v-btn>
                <v-btn>中</v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col class="pa-1">
              <div class="helper-text">This is a default Welcome message:</div>
              <div class="mb-1 style-12">
                (defined in cs-design-system for Login Page Welcome message)
              </div>
              <div class="mb-2 pa-2 message-row">
                <h3 class="mt-2 style-03">
                  {{ $t('cs.login.welcomeMessage', { appName: 'Demo' }) }}
                </h3>
                <div class="mt-2 style-12">
                  {{ $t('cs.login.welcomeMessageSubText', { appName: 'Demo' }) }}
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col class="pa-1">
              <div class="helper-text">This is a customized Welcome message:</div>
              <div class="mb-1 style-12">
                (defined in app to overide the Login Page Welcome message)
              </div>
              <div class="mb-2 pa-2 message-row">
                <h3 class="mt-2 style-03">
                  {{ $t('custom.login.welcomeMessage', { appName: 'Demo' }) }}
                </h3>
                <div class="mt-2 style-12">
                  {{ $t('custom.login.welcomeMessageSubText', { appName: 'Demo' }) }}
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col class="pa-1">
              <div class="helper-text">This is Application message:</div>
              <div class="mb-1 style-12">(defined in app)</div>
              <div class="mb-2 pa-2 message-row">
                <span class="mt-2 style-03">
                  {{ $t('csd.demo.message', { appName: 'Demo' }) }}
                </span>
                <span class="mt-2 style-11">
                  {{ $t('csd.demo.language', { code: locale }) }}
                </span>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </template>
  </cs-view>
</template>

<script>
import { translator } from '@complispace/cs-design-system';

export default {
  name: 'InternationalizationPage',

  data() {
    return {
      localeIndex: 0,
      locale: 'en'
    };
  },

  methods: {
    onLanguageChange(localeIndex) {
      if (localeIndex === 1) {
        this.locale = 'zh';
      } else {
        this.locale = 'en';
      }
      translator.setLocale(this.locale);
    }
  }
};
</script>
<style scoped>
.message-row {
  align-self: center;
  border: 1px solid rgb(220, 220, 220);
}
</style>
