<template>
  <cs-view single-view>
    <template #content>
      <v-container>
        <v-row>
          <v-col class="col-12 version">cs-apex-definitions v{{ version }}</v-col>
        </v-row>
        <v-row class="ma-2">
          <v-col class="col-12 pa-0">
            <color-palette title="Colors" :color-definitions="colors"></color-palette>
          </v-col>
        </v-row>
        <v-row class="ma-2">
          <v-col class="col-12 pa-0">
            <v-card class="ma-0 pa-2">
              <v-card-title>Complispace Colors</v-card-title>
              <v-tabs v-model="themeTab" color="primary" show-arrows>
                <v-tab :value="1">Vue 3</v-tab>
                <v-tab :value="2">Vue 2</v-tab>
              </v-tabs>
              <v-divider />
              <v-window v-model="themeTab">
                <v-window-item :value="1">
                  <color-palette
                    v-for="(themeValue, name) in vuetifyThemesV3"
                    :key="name"
                    :title="`Theme: ${name}`"
                    :color-definitions="getColorDefinitionsV3(themeValue)"
                  ></color-palette>
                </v-window-item>
                <v-window-item :key="2" :value="2">
                  <color-palette
                    v-for="(themeValue, name) in vuetifyThemes"
                    :key="name"
                    :title="`Theme: ${name}`"
                    :color-definitions="getColorDefinitions(themeValue)"
                  ></color-palette>
                </v-window-item>
              </v-window>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="ma-2">
          <v-col class="col-12 pa-0">
            <typography :color-definitions="colors"></typography>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </cs-view>
</template>

<script>
import { mapMutations } from 'vuex';
import { CSBase } from '@complispace/cs-design-system';
import definitions from '@complispace/cs-apex-definitions';
import * as types from '../store/mutationTypes';
import ColorPalette from '../components/ColorPalette';
import { dependencies } from '../../package.json';
import Typography from '../components/Typography';

export default {
  name: 'ApexDefinitions',

  components: {
    'color-palette': ColorPalette,
    typography: Typography
  },

  extends: CSBase,

  data() {
    return {
      themeTab: 0,
      colors: definitions.helixifyColors,
      vuetifyThemes: definitions.helixifyVuetifyDefinitions.theme.themes,
      vuetifyThemesV3: definitions ? definitions.helixifyVuetifyDefinitionsV3.theme.themes : []
    };
  },
  computed: {
    version() {
      return dependencies['@complispace/cs-apex-definitions'];
    }
  },
  async created() {
    this.colorsMap = this.createColorMap();
    this.vuetifyThemes = this.getVuetifyThemes();
  },
  async mounted() {
    this.setPageViewType('apex-definitions');
  },
  methods: {
    ...mapMutations({
      setPageViewType: types.VIEW_TYPE_SET_PAGE_VIEW_TYPE
    }),
    createColorMap() {
      const { helixifyColors } = definitions;
      const map = new Map();
      Object.keys(helixifyColors).forEach((colorName) => {
        const colorDef = helixifyColors[colorName];
        map.set(colorDef.base, { colorName, base: colorDef.base });
      });
      return map;
    },
    getNamedColor(colorCode) {
      const colorDef = this.colorsMap.get(colorCode);
      return { colorName: colorDef.colorName, base: colorDef.base } || {};
    },
    getColorDefinitions(themeColors) {
      const namedColors = {};
      if (!themeColors || !this.colorsMap) {
        return namedColors;
      }
      Object.keys(themeColors).forEach((key) => {
        const colorDef = themeColors[key];
        if (typeof colorDef === 'string') {
          namedColors[key] = this.getNamedColor(colorDef);
          return;
        }
        Object.keys(colorDef).forEach((c) => {
          let colorName = key;
          if (c !== 'base') {
            colorName = `${key}-${c}`;
          }
          namedColors[colorName] = this.getNamedColor(colorDef[c]);
        });
      });
      return namedColors;
    },
    getColorDefinitionsV3(themeColors) {
      const namedColors = {};
      if (!themeColors || !this.colorsMap) {
        return namedColors;
      }
      Object.keys(themeColors.colors).forEach((key) => {
        const colorDef = themeColors.colors[key];
        namedColors[key] = this.getNamedColor(colorDef);
      });
      return namedColors;
    },
    getVuetifyThemes() {
      const { themes } = definitions.helixifyVuetifyDefinitions.theme;
      const ceThemes = definitions.helixifyVuetifyCeDefinitions.theme.themes;
      Object.keys(ceThemes).forEach((theme) => {
        themes[`ce-${theme}`] = ceThemes[theme];
      });
      return themes;
    }
  }
};
</script>

<style scoped>
.version {
  font-size: 9pt;
  padding-left: 18px;
}
</style>
