import { createApp, h } from 'vue';
import CSDesignSystem, { vuetify, translator } from '@complispace/cs-design-system';
import JsonEditorVue from 'json-editor-vue';
import App from './App';
import router from './router';
import store from './store';
import SlotIndicator from './components/SlotIndicator';

const options = {
  store,
  router,
  auth0: {
    appHost: window.location.host,
    appUrl: window.location.origin,
    auth0Domain: process.env.VUE_APP_AUTH0_DOMAIN,
    auth0ClientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
    auth0Audience: process.env.VUE_APP_AUTH0_AUDIENCE,
    appEnv: process.env.VUE_APP_APP_ENV || 'develop'
  },
  gainsight: {
    gainsightSiteInfoApiHost: process.env.VUE_APP_GAINSIGHT_SITE_INFO_API_HOST,
    gainsightProductTag: process.env.VUE_APP_GAINSIGHT_PRODUCT_TAG
  },
  login: {
    resetBtnTimeout: parseInt(process.env.VUE_APP_LOGIN_RESET_TIMEOUT || 5, 10)
  }
};

const app = createApp({
  render: () => h(App)
});

app.component('SlotIndicator', SlotIndicator);

const reqRes = require.context(`./resources/i18n`, true);
translator.applyAppI18n(reqRes);

// Complispace design system
app.use(CSDesignSystem, options);
app.use(vuetify);
app.use(router);
app.use(store);

app.use(JsonEditorVue, {
  // global props & attrs (one-way data flow)
});
app.mount('#app');
