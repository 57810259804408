import mutations from './mutations';

const state = {
  enabled: false
};

const getters = {
  enabled: (stateData) => !!stateData.enabled
};

export default {
  namespaced: true,
  state,
  mutations,
  getters
};
