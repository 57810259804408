<template>
  <v-card class="ma-0 pa-2">
    <div class="justify-center pt-2">Testing CS Form Dialog</div>

    <v-container>
      <v-row class="mb-3">
        <cs-button
          label="Open Form Dialog"
          @click="onShowPrimaryDialog('Dialog Title', 'This is a dialog subheading')"
        />
      </v-row>

      <v-row class="mb-3">
        <cs-button
          label="No subheading no actions"
          @click="onShowDialog('No Sub Heading Dialog', null)"
        />
      </v-row>
      <v-row class="mb-3">
        <cs-button
          label="No Heading (will not show)"
          @click="onShowDialog('', 'No Heading Dialog')"
        />
      </v-row>
      <v-row class="mb-3">
        <cs-button
          label="Long Text"
          @click="onShowLongTextDialog('Long Text dialog', 'This is a dialoge subheading')"
        />
      </v-row>
      <v-row class="mb-3">
        <cs-button label="Dialog with Delete and Secondary" @click="dialogDelete = true" />
      </v-row>
      <v-row class="mb-3">
        <cs-button label="Dialog with Delete and 2 Secondaries" @click="dialogDelete2 = true" />
      </v-row>
      <v-row class="mb-3">
        <cs-button
          label="Dialog with Secondary, Delete and Primary"
          @click="dialogDeletePrimary = true"
        />
      </v-row>
      <v-row class="mb-3">
        <cs-button
          label="Dialog exit 3 Buttons (will not show)"
          @click="dialogExtraButton = true"
        />
      </v-row>
    </v-container>

    <cs-form-dialog id="form-dialog-1" v-model="dialogNoSubHeading" :heading="heading">
      <template #cs-form-dialog-content>
        {{ dialogContent }}
      </template>
    </cs-form-dialog>
    <cs-form-dialog
      id="form-dialog-2"
      v-model="dialog"
      :heading="heading"
      :subheading="subheading"
      :primary-action="{
        label: 'Agree',
        disabled: disablePrimary,
        loading: primaryActionLoading,
        rightIcon: 'mdi-check-circle'
      }"
      :secondary-action1="{
        label: 'information alert',
        disabled: disableInfoAlert,
        leftIcon: 'mdi-information-outline'
      }"
      :secondary-action2="{
        label: 'demonstrates action loading',
        loading: secondaryActionLoading,
        leftIcon: 'mdi-gesture-tap'
      }"
      @primary-click="onDlgOkClick"
      @secondary2-click="onDlgWarningClick"
      @secondary1-click="onDlgInfoClick"
    >
      <template #cs-form-dialog-content>
        <v-form>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Type here to enable buttons"
                  variant="outlined"
                  @update:model-value="onFormInput"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-combobox
                  v-model="select"
                  :items="['Programming', 'Design', 'Vue', 'Vuetify']"
                  label="Combobox"
                  multiple
                  variant="outlined"
                  density="compact"
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-combobox
                  v-model="select"
                  :items="['Programming', 'Design', 'Vue', 'Vuetify']"
                  label="Combobox"
                  multiple
                  variant="outlined"
                  density="compact"
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-combobox
                  v-model="select"
                  :items="['Programming', 'Design', 'Vue', 'Vuetify']"
                  label="Combobox"
                  multiple
                  variant="outlined"
                  density="compact"
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-combobox
                  v-model="select"
                  :items="['Programming', 'Design', 'Vue', 'Vuetify']"
                  label="Combobox"
                  multiple
                  variant="outlined"
                  density="compact"
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-combobox
                  v-model="select"
                  :items="['Programming', 'Design', 'Vue', 'Vuetify']"
                  label="Combobox"
                  multiple
                  variant="outlined"
                  density="compact"
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-radio-group>
                  <v-radio label="Option 1" value="radio-1"> </v-radio>
                  <v-radio label="Option 2" value="radio-2"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-file-input
                label="File input"
                variant="filled"
                prepend-icon="mdi-camera"
              ></v-file-input>
            </v-row>
          </v-container>
        </v-form>
      </template>
    </cs-form-dialog>
    <cs-form-dialog
      id="form-dialog-delete"
      v-model="dialogDelete"
      heading="Dialog with Delete and Secondary Button"
      :delete-action="{
        label: 'Delete',
        loading: deleteActionLoading,
        leftIcon: 'mdi-information-outline'
      }"
      :secondary-action1="{
        label: 'Information'
      }"
      @delete-click="onDlgDeleteClick"
      @secondary1-click="onDlgInfoClick"
    >
      <template #cs-form-dialog-content> Are you sure you want to proceed to delete? </template>
    </cs-form-dialog>
    <cs-form-dialog
      id="form-dialog-delete2"
      v-model="dialogDelete2"
      heading="Dialog with Delete and 2 Secondaries Button"
      :delete-action="{ label: 'DELETE' }"
      :secondary-action1="{ label: 'information alert' }"
      :secondary-action2="{
        label: 'demonstrates action loading',
        loading: secondaryActionLoading
      }"
      @delete-click="onDlgDelete2Click"
      @secondary2-click="onDlgWarningClick"
      @secondary1-click="onDlgInfoClick"
    >
      <template #cs-form-dialog-content> Are you sure you want to proceed to delete? </template>
    </cs-form-dialog>
    <cs-form-dialog
      id="form-dialog-delete-primary"
      v-model="dialogDeletePrimary"
      heading="Dialog with Delete and Primary Button"
      :primary-action="{ label: 'Continue' }"
      :delete-action="{ label: 'Remove' }"
      :secondary-action1="{ label: 'information alert' }"
      @delete-click="onDlgRemoveClick"
      @primary-click="onDlgDeletePrimaryOkClick"
      @secondary1-click="onDlgInfoClick"
    >
      <template #cs-form-dialog-content>
        Clicks Continue to continue the updates, clicks Remove to remove the item.
      </template>
    </cs-form-dialog>
    <cs-form-dialog
      id="form-dialog-delete-primary"
      v-model="dialogExtraButton"
      heading="Dialog with Extra Button"
      :primary-action="{
        label: 'Continue'
      }"
      :delete-action="{
        label: 'Remove'
      }"
      :secondary-action1="{
        label: 'information alert'
      }"
      :secondary-action2="{
        label: 'extra button'
      }"
    >
      <template #cs-form-dialog-content>
        This dialog will not be shown due to more than 3 buttons!
      </template>
    </cs-form-dialog>
  </v-card>
</template>

<script>
import { CSFormDialog } from '@complispace/cs-design-system';

export default {
  name: 'FormDialogsTest',
  components: { 'cs-form-dialog': CSFormDialog },

  data() {
    return {
      dialog: false,
      dialogNoSubHeading: false,
      heading: 'testing',
      subheading: '',
      dialogContent: undefined,
      select: '',
      disablePrimary: true,
      disableInfoAlert: true,
      primaryActionLoading: false,
      secondaryActionLoading: false,
      dialogDelete: false,
      dialogDelete2: false,
      deleteActionLoading: false,
      dialogDeletePrimary: false,
      dialogExtraButton: false
    };
  },

  methods: {
    onDlgOkClick() {
      this.primaryActionLoading = true;
      setTimeout(() => {
        this.primaryActionLoading = false;
        this.closeDialog();
      }, 5000);
    },
    closeDialog() {
      this.dialog = false;
      this.dialogNoSubHeading = false;
    },
    onDlgWarningClick() {
      this.secondaryActionLoading = true;
      setTimeout(() => {
        this.secondaryActionLoading = false;
        this.showDialogAlert('warning');
      }, 2000);
    },
    onDlgInfoClick() {
      this.showDialogAlert('info');
    },
    onDlgDeleteClick() {
      this.deleteActionLoading = true;
      setTimeout(() => {
        this.deleteActionLoading = false;
        this.showDialogAlert('Deleted');
        this.dialogDelete = false;
      }, 2000);
    },
    onDlgDelete2Click() {
      this.dialogDelete2 = false;
    },
    onDlgRemoveClick() {
      this.dialogDeletePrimary = false;
    },
    onDlgDeletePrimaryOkClick() {
      this.dialogDeletePrimary = false;
    },
    onFormInput(val) {
      if (val) {
        this.disablePrimary = false;
        this.disableInfoAlert = false;
      } else {
        this.disablePrimary = true;
        this.disableInfoAlert = true;
      }
    },
    showDialogAlert(type) {
      const message =
        'This configuration will prevent users from logging in via Ideagen Home’s authentication solution. Make sure all fields below are filled in correctly in order to prevent any login issues.';
      this.$store.commit('cs-appearance/setAlert', {
        type,
        message,
        onDialogOnly: true
      });
    },
    onShowDialog(headingText, subheadingText) {
      this.heading = headingText;
      if (subheadingText !== null) {
        this.subheading = subheadingText;
        this.dialog = true;
      } else {
        this.dialog = false;
        this.dialogNoSubHeading = true;
      }
      this.dialogContent = 'This is a simple text.....';
    },
    onShowPrimaryDialog(headingText, subheadingText) {
      this.onShowDialog(headingText, subheadingText);
    },
    onShowLongTextDialog(headingText) {
      this.onShowDialog(headingText, null);
      this.dialogContent =
        'This is where long text goes. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborun. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat.';
    }
  }
};
</script>
