import { appsyncClient } from '@/infrastructure/appsync-client';

const getPDF = /* GraphQL */ `
  query getPDF($id: String!) {
    getPDF(id: $id) {
      id
      name
      status
      location
    }
  }
`;

const createPDF = /* GraphQL */ `
  mutation createPDF($input: PDFInput!) {
    createPDF(input: $input) {
      id
      name
      status
      location
    }
  }
`;

const onCreatePDF = /* GraphQL */ `
  subscription onCreatePDF($id: String!, $status: CreatePDFStatus!) {
    onCreatePDF(id: $id, status: $status) {
      id
      name
      status
      location
    }
  }
`;

const onPDFCreated = /* GraphQL */ `
  subscription onPDFCreated($id: String!, $status: CreatePDFStatus!) {
    onPDFCreated(id: $id, status: $status) {
      id
      name
      status
      location
    }
  }
`;

export default {
  async queryGetPDF({ state }, id) {
    const token = await this.dispatch('cs-session/getToken');
    const client = appsyncClient(state.config, token);
    const { data } = await client.query(getPDF, { id });
    return data.getPDF;
  },

  async mutateCreatePDF({ state }, { id, name, content, headerContent, footerContent }) {
    const token = await this.dispatch('cs-session/getToken');
    const client = appsyncClient(state.config, token);
    return client.query(createPDF, { input: { id, name, content, headerContent, footerContent } });
  },

  async subscribeOnCreatePDF({ state }, { id, status, next, error }) {
    const token = await this.dispatch('cs-session/getToken');
    const client = appsyncClient(state.config, token);
    return client.subscribe(onCreatePDF, { id, status }, next, error);
  },

  async subscribeOnPDFCreated({ state }, { id, status, next, error }) {
    const token = await this.dispatch('cs-session/getToken');
    const client = appsyncClient(state.config, token);
    return client.subscribe(onPDFCreated, { id, status }, next, error);
  }
};
