import mutations from './mutations';

const state = {
  viewType: 'single-view'
};

export default {
  namespaced: true,
  state,
  mutations
};
