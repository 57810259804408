import mutations from './mutations';
import getters from './getters';
import actions from './actions';

const state = {
  config: null
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
